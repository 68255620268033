import React, { FC, startTransition } from 'react';
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import CampaignIcon from '@mui/icons-material/Campaign';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ReportIcon from '@mui/icons-material/Report';
import Logo from '../../Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from '../../../Router/routes';
import useStyles from './style';

interface ISidebar {
  drawerOpened: boolean;
  onCloseDrawer: () => void;
  setLoading: (value: boolean) => void;
}

const SideBar: FC<ISidebar> = (props) => {
  const classes = useStyles();
  const { drawerOpened, onCloseDrawer, setLoading } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleClickItem = (path: string) => {
    if (currentPath !== path) {
      setLoading(true);
      startTransition(() => {
        setLoading(false);
        navigate(path);
      });
    }
  };

  return (
    <nav aria-label="mailbox folders">
      <Drawer
        anchor="left"
        open={drawerOpened}
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box className={classes.drawerHeader}>
          <Grid container>
            <Grid item xs={10}>
              <Logo height={38} />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={onCloseDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleClickItem(routes.DASHBOARD)}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleClickItem(routes.USERS)}>
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primary="Utilisateurs" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleClickItem(routes.CONTACT)}>
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
              <ListItemText primary="Contact" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleClickItem(routes.RAPPORT)}>
              <ListItemIcon>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText primary="Rapport" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleClickItem(routes.ABONNEMENT)}>
              <ListItemIcon>
                <LoyaltyIcon />
              </ListItemIcon>
              <ListItemText primary="Abonnement" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleClickItem(routes.PUBS)}>
              <ListItemIcon>
                <CampaignIcon />
              </ListItemIcon>
              <ListItemText primary="Publicités" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </nav>
  );
};

export default SideBar;
