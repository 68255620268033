import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  {
    hide: {
      display: 'none',
    },
    menuButton: {
      marginRight: 16,
    },
    toolBarWrapper: {
      backgroundColor: '#3e3d40',
      color: 'white',
      height: '100%',
      '& .css-fvn0fs-MuiContainer-root': {
        paddingRight: '0%',
        maxWidth: '100%',
      },
    },
    toolBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .css-11lq3yg-MuiGrid-root': {
        width: '9%',
      },
    },

    profileContainer: {
      marginLeft: 'auto',
      textAlign: 'right',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },

    imgContainer: {
      height: '40px',
      width: '50%',
    },

    img: {
      height: '40px',
      width: '40px',
      borderRadius: '50%',
    },
    logo: {
      width: '75%',
    },

    menu: {
      color: 'white !important',
      borderRadius: '32px !important',
      borderColor: 'transparent !important',
      height: '50px',
      width: '90%',
    },

    icon: {
      paddingRight: '3px',
    },
  },
  { name: 'navHead' },
);

export default useStyles;
