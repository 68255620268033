const routes = {
  DASHBOARD: '/',
  LOGIN: '/login',
  USERS: '/users',
  ABONNEMENT: '/abonnement',
  PUBS: '/ads',
  CONTACT: '/contact',
  RAPPORT: '/report',
  //PUBS: '/pubs',
  MADAGASCAR_MAP: '/madagascar',
  PAYMENT_SUCCESS: '/success',
  PAYMENT_CANCELED: '/cancel',
};

export default routes;
