import React, { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from './routes';
import Protected from './ProtectedRoute';

interface RouteInterface {
  key: string;
  path: string;
  isPrivate: boolean;
  component: React.LazyExoticComponent<() => JSX.Element>;
}
const Login = lazy(() => import('../Pages/Authentication'));
const Users = lazy(() => import('../Pages/Users'));
const Dashboard = lazy(() => import('../Pages/Statistique'));
const Pubs = lazy(() => import('../Pages/Publicitaire'));
const Abonnement = lazy(() => import('../Pages/Abonnement'));
const Contact = lazy(() => import('../Pages/Contact'));
const Report = lazy(() => import('../Pages/Report'));
const Madagascar = lazy(() => import('../Pages/MadagascarMap'));
const PaymentSuccess = lazy(() => import('../Pages/PaymentSuccess'));
const PaymentCanceled = lazy(() => import('../Pages/PaymentCanceled'));

const routesCo: RouteInterface[] = [
  {
    key: 'Dashboard',
    path: routes.DASHBOARD,
    component: Dashboard,
    isPrivate: true,
  },
  {
    key: 'Login',
    path: routes.LOGIN,
    component: Login,
    isPrivate: false,
  },
  {
    key: 'Users',
    path: routes.USERS,
    component: Users,
    isPrivate: true,
  },
  {
    key: 'Pubs',
    path: routes.PUBS,
    component: Pubs,
    isPrivate: true,
  },
  {
    key: 'Abonnement',
    path: routes.ABONNEMENT,
    component: Abonnement,
    isPrivate: true,
  },
  {
    key: 'Contact',
    path: routes.CONTACT,
    component: Contact,
    isPrivate: true,
  },
  {
    key: 'Report',
    path: routes.RAPPORT,
    component: Report,
    isPrivate: true,
  },
  {
    key: 'MadagascarMap',
    path: routes.MADAGASCAR_MAP,
    component: Madagascar,
    isPrivate: false,
  },
  {
    key: 'PaymentSuccess',
    path: routes.PAYMENT_SUCCESS,
    component: PaymentSuccess,
    isPrivate: false,
  },
  {
    key: 'PaymentCanceled',
    path: routes.PAYMENT_CANCELED,
    component: PaymentCanceled,
    isPrivate: false,
  },
];

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routesCo?.map((item) => {
          const { key, path, isPrivate, component: Component } = item;
          return (
            <Route
              key={key}
              path={path}
              element={
                isPrivate ? (
                  <Protected>
                    <Component />
                  </Protected>
                ) : (
                  <Component />
                )
              }
            />
          );
        })}
        <Route path="*" element={<div>Not Found</div>} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
