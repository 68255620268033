import React, { FC, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useLocalStorage } from '../Hooks/useLocalStorage';
import { AppBar } from '@mui/material';
import Layout from '../Components/Layout';
import { useGetMe } from '../Api/user';

interface IProtected {
  children: JSX.Element;
}

const Protected: FC<IProtected> = (props) => {
  const { children } = props;
  const [token] = useLocalStorage('token', null);
  const [error, setError] = useState<number>();

  const getMe = useGetMe();

  const fetchMe = async () => {
    try {
      await getMe();
    } catch (err: any) {
      console.error(err.response.status);
      setError(err.response.status)

    }
  };

  useEffect(() => {
    fetchMe();
  }, []);

  if (!token || error === 401) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <AppBar position="sticky" sx={{ zIndex: 'auto', color: '#000' }}>
        <Layout children={children} />
      </AppBar>
    </>
  );
};

export default Protected;
