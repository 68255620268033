import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  {
    drawer: {
      width: 240,
      flexShrink: 0,
    },
    drawerPaper: {
      width: 240,
    },
    drawerHeader: {
      padding: '0 14px',
      minHeight: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '& .css-11lq3yg-MuiGrid-root': {
        margin: '6px',
      },
      '& .css-17p3wh3-MuiGrid-root': {
        justifyContent: 'center',
        position: 'relative',
        alignItems: 'center',
        display: 'flex',
      },
      '& .css-1o7apob-MuiGrid-root': {
        position: 'relative',
        display: 'flex',
      },
      '& button': {
        width: '50px',
      },
    },
  },
  { name: 'sideBar' },
);

export default useStyles;
