import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import NavHead from './NavHead';
import SideBar from './SideBar';
import clsx from 'clsx';
import useStyles from './style';
import { MutatingDots } from 'react-loader-spinner';

interface ILayout {
  children: any;
}

const Layout: FC<ILayout> = (props) => {
  const classes = useStyles();
  const { children } = props;
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  return (
    <Box display="flex">
      <CssBaseline />
      <NavHead
        drawerOpened={drawerOpened}
        onOpenDrawer={() => setDrawerOpened(true)}
        setLoading={setLoading}
      />
      <SideBar
        drawerOpened={drawerOpened}
        onCloseDrawer={() => setDrawerOpened(false)}
        setLoading={setLoading}
      />
      {loading ? (
        <div className={classes.loaderContainer}>
          <MutatingDots
            height="100"
            width="100"
            color="rgb(34 64 132)"
            secondaryColor="rgb(34 64 132)"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </div>
      ) : (
        <main
          className={clsx(
            classes.content,
            drawerOpened ? classes.contentShift : classes.contentUnshift,
          )}
        >
          {children}
        </main>
      )}
    </Box>
  );
};

export default Layout;
