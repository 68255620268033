import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  {
    content: {
      backgroundColor: 'white',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      position: 'relative',
      '&::before': {
        content: `''`,
        ...{
          minHeight: 70,
        },
      },
    },
    contentUnshift: {
      transition: 'margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      marginLeft: -240,
    },
    contentShift: {
      transition: 'margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      marginLeft: 0,
      maxWidth: `calc(100% - 240px)`,
    },
    loaderContainer: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      background: 'rgba(0, 0, 0, 0.834)',
      zIndex: 1,
      opacity: 0.5,
    },
  },
  { name: 'layout' },
);

export default useStyles;
