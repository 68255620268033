import axios from 'axios';
import { CONSTANTS } from '../Utils/constants';
import { config } from '../Utils/config';

export const useAxios = () => {
  const tokenString = localStorage.getItem(CONSTANTS.localStorage.token);

  const token = tokenString ? JSON.parse(tokenString) : tokenString;

  const instance = axios.create({
    baseURL: `${config.apiUrl}/api/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return instance;
};

export const useInstanceAxios = () => {
  const instance = axios.create({
    baseURL: `${config.apiUrl}/api/`,
    headers: {},
  });
  return instance;
};
