import { useAxios } from './axios';

export const useGetMe = () => {
  const axios = useAxios();
  return async () => {
    return axios.get('users/me');
  };
};

export const useGetUsers = () => {
  const axios = useAxios();
  return async () => {
    return axios.get('users');
  };
};

export const useGetUsersApp = () => {
  const axios = useAxios();
  return async () => {
    return axios.get('users?populate=*&filters[role][type][$ne]=admin');
  };
};

export const useGetUsersMen = () => {
  const axios = useAxios();
  return async () => {
    return axios.get('users?filters[gender][$eq]=Homme');
  };
};

export const useGetUsersWomen = () => {
  const axios = useAxios();
  return async () => {
    return axios.get('users?filters[gender][$eq]=Femme');
  };
};

export const useGetLesbienne = () => {
  const axios = useAxios();
  return async () => {
    return axios.get('users?filters[sexuality][$eq]=Lesbienne');
  };
};

export const useGetTransgenre = () => {
  const axios = useAxios();
  return async () => {
    return axios.get('users?filters[sexuality][$eq]=Transgenre');
  };
};

export const useGetGays = () => {
  const axios = useAxios();
  return async () => {
    return axios.get('users?filters[sexuality][$eq]=Gay');
  };
};

export const useGetBisexual = () => {
  const axios = useAxios();
  return async () => {
    return axios.get('users?filters[sexuality][$eq]=Bisexuel');
  };
};

export const useBirthday = () => {
  const axios = useAxios();
  return async () => {
    return axios.get('users?filters[dateOfBirth][$null]');
  };
};

export const useGetRoleUser =
  'https://blablachat-api-stage.hairun-technology.com/api/users/me?populate=role';
