import React, { FC, MouseEvent, startTransition, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import CampaignIcon from '@mui/icons-material/Campaign';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ReportIcon from '@mui/icons-material/Report';
import profil from '../../../assets/profil.png';
import blabla from '../../../assets/blabla.png';
import clsx from 'clsx';
import { useLocalStorage } from '../../../Hooks/useLocalStorage';
import routes from '../../../Router/routes';
import useStyles from './style';

interface INavHead {
  drawerOpened: boolean;
  onOpenDrawer: () => void;
  setLoading: (value: boolean) => void;
}

const NavHead: FC<INavHead> = (props) => {
  const classes = useStyles();
  const { drawerOpened, onOpenDrawer, setLoading } = props;
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const open = Boolean(anchorElement);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    startTransition(() => {
      setAnchorElement(e.currentTarget);
    });
  };

  const [, setToken] = useLocalStorage('token', null);

  const handleClose = (path: string) => {
    if (currentPath !== path) {
      setLoading(true);
      startTransition(() => {
        if (path === routes.LOGIN) {
          setToken(null);
        }
        setLoading(false);
        setAnchorElement(null);
        navigate(path);
      });
    }
  };

  return (
    <AppBar position="fixed" elevation={1}>
      <Toolbar className={classes.toolBarWrapper}>
        <Container
          sx={{
            maxWidth: {
              xl: '95%',
              lg: '95%',
            },
          }}
        >
          <Box className={classes.toolBar}>
            <Grid container>
              <Grid item xs={6}>
                <div>
                  <img src={blabla} alt="logo" className={classes.logo} />
                </div>
              </Grid>
              <Grid item xs={6}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onOpenDrawer}
                  edge="start"
                  className={clsx(classes.menuButton, drawerOpened && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Box className={classes.profileContainer}>
              <div className={classes.imgContainer}>
                <img src={profil} alt="profil" className={classes.img} />
              </div>
              <Button
                id="menu-button"
                variant="outlined"
                aria-controls={open ? 'menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => handleClick(e)}
                endIcon={<ExpandMoreIcon />}
                className={classes.menu}
              ></Button>
              <Menu
                id="menu"
                anchorEl={anchorElement}
                open={open}
                onClose={() => setAnchorElement(null)}
                MenuListProps={{
                  'aria-labelledby': 'menu-button',
                }}
              >
                <MenuItem onClick={() => handleClose(routes.USERS)}>
                  <ManageAccountsIcon className={classes.icon} /> Gestion utilisateurs
                </MenuItem>
                <MenuItem onClick={() => handleClose(routes.CONTACT)}>
                  <ContactMailIcon className={classes.icon} /> Contact
                </MenuItem>
                <MenuItem onClick={() => handleClose(routes.RAPPORT)}>
                  <ReportIcon className={classes.icon} /> Rapport
                </MenuItem>
                <MenuItem onClick={() => handleClose(routes.ABONNEMENT)}>
                  <LoyaltyIcon className={classes.icon} /> Abonnement
                </MenuItem>
                <MenuItem onClick={() => handleClose(routes.PUBS)}>
                  <CampaignIcon className={classes.icon} /> Publicitaire
                </MenuItem>
                <MenuItem onClick={() => handleClose(routes.DASHBOARD)}>
                  <DashboardIcon className={classes.icon} /> Dashboard
                </MenuItem>
                <MenuItem onClick={() => handleClose(routes.LOGIN)}>
                  <LogoutIcon className={classes.icon} /> Déconnexion
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default NavHead;
